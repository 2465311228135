import React from 'react';
import {Spinner} from "reactstrap";

const Loading = ({ hasError }) => {
    return (
        <div className="loading-container">
            {hasError ?
                <>
                    <p>Error tijdens het laden!<br/>Probeer het later opnieuw</p>
                </>
                :
                <>
                    <Spinner />
                    <p>Aan het laden...</p>
                </>
            }
        </div>
    );
};

export default Loading;