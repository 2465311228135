import React from 'react';
import {Button, Col, Row, Spinner} from "reactstrap";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import MyTextInput from "./MyTextInput";

const FillInEventPassword = ({ setPassword, isPasswordCorrect = null }) => {
    return (
        <div className="fill-in-event-code-container">
            <Formik
                initialValues={{
                    eventPassword: '',
                }}
                validationSchema={Yup.object({
                    eventPassword: Yup.string()
                        .required("Verplicht"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    const { eventPassword } = values;

                    setPassword(eventPassword);
                }}
            >
                <Form>
                    <p>Vul hier het wachtwoord in</p>
                    {isPasswordCorrect === false ? <p style={{color: 'red'}}>Wachtwoord is niet juist!</p> : null}
                    <MyTextInput
                        label="Event wachtwoord"
                        name="eventPassword"
                        type="password"
                    />

                    <br /><br />

                    <Button type="submit">Doorgaan</Button>
                </Form>
            </Formik>
        </div>
    );
};

export default FillInEventPassword;