import React from 'react';
import {Button, Col, Row, Spinner} from "reactstrap";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import MyTextInput from "./MyTextInput";

const FillInEventCode = () => {
    return (
        <div className="fill-in-event-code-container">
            <Formik
                initialValues={{
                    eventCode: '',
                }}
                validationSchema={Yup.object({
                    eventCode: Yup.string()
                        .max(6, "Event code kan niet langer zijn dan 6 cijfers")
                        .required("Verplicht"),
                })}
                onSubmit={async (values, { setSubmitting }) => {
                    const { eventCode } = values;

                    window.location.href = `${window.location.origin}/?eventCode=${eventCode}`;
                }}
            >
                <Form>
                    <p>Vul hier de 6-cijferige evenement code in</p>
                    <MyTextInput
                        label="Event code"
                        name="eventCode"
                        type="text"
                    />

                    <br /><br />

                    <Button type="submit">Doorgaan</Button>
                </Form>
            </Formik>
        </div>
    );
};

export default FillInEventCode;