import React from 'react';
import {useField} from "formik";
import {FormGroup, Input, Label} from "reactstrap";

const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
        <>
            <FormGroup floating>
                <Input className="text-input" {...field} {...props} />
                {meta.touched && meta.error ? (
                    <div className="error" style={{color: 'red'}}>{meta.error}</div>
                ) : null}
                <Label htmlFor={props.id || props.name}>{label}</Label>
            </FormGroup>
        </>
    );
};

export default MyTextInput;