import './App.css';
import React from 'react';
import logo from './SmartClips.png';
import AdminPanel from "./AdminPanel";
import Loading from "./Loading";
import qs from "qs";
import {withRouter} from "react-router-dom";
import FillInEventCode from "./FillInEventCode";
import FillInEventPassword from "./FillInEventPassword";

const App = (props) => {
    const { eventCode } = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    return (
        <_App eventCode={eventCode} />
    );
}

class _App extends React.Component {
    constructor(props) {
        super(props);

        this.errorWhileLoadingTimeoutRef = null;

        this.state = {
            eventInfo: null,
            results: [],
            lastTimeUpdated: null,
            errorWhileLoading: false,
            hasEventCode: false,
            eventPassword: null,
            isPasswordCorrect: false,
        };
    }

    componentDidMount() {
        if (this.props.eventCode && this.props.eventCode >= 100000 && this.props.eventCode <= 999999) {
            this.setState({
                hasEventCode: true,
            });
            this.initialize(this.props.eventCode);
        }
    }

    getFromBackend = async (path, method = 'GET', body = null) => {
        return new Promise(function(resolve, reject) {
            const xhr = new XMLHttpRequest();

            xhr.open(method, `https://smart-clips-event-service-clokdc3sja-ez.a.run.app/${path}`, true);
            if (method === 'POST') {
                xhr.setRequestHeader('Content-Type', 'application/json');
            }
            xhr.addEventListener('load', function () {
                if (this.status >= 200 && this.status < 300) {
                    resolve(xhr.response);
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText,
                    });
                }
            });
            xhr.onError = function() {
                reject({
                    status: this.status,
                    statusText: xhr.statusText,
                });
            }
            xhr.send(body);
        });
    }

    initialize = (eventCode) => {
        // Retrieve info from backend
        const xhr = new XMLHttpRequest();

        xhr.addEventListener('load', () => {
            this.setState({
                eventInfo: JSON.parse(xhr.responseText),
            });
        });

        xhr.open('GET', `https://smart-clips-event-service-clokdc3sja-ez.a.run.app/info/${eventCode}`, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send();

        // Also set a timeout, to display an error when the application could not be loaded in time.
        this.errorWhileLoadingTimeoutRef = setTimeout(() => {
            this.setState({
                errorWhileLoading: true,
            });
        }, 5000);

        this.retrieveResults();
    }

    retrieveResults = () => {
        if (this.props.eventCode === '') return;

        const xhr = new XMLHttpRequest();

        xhr.addEventListener('load', () => {
            this.setState({
                results: JSON.parse(xhr.responseText),
                lastTimeUpdated: new Date(),
            });
        });

        xhr.open('GET', `https://smart-clips-event-service-clokdc3sja-ez.a.run.app/results/${this.props.eventCode}`, true);
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.send();
    };

    componentWillUnmount() {
        clearTimeout(this.errorWhileLoadingTimeoutRef);
    }

    getHeader = () => {
        let color = 'ff00cfb4';
        let name = null;
        let eventLogo = logo;
        if (this.state.eventInfo !== null) {
            if (this.state.eventInfo['color']) color = this.state.eventInfo['color'];
            if (this.state.eventInfo['name']) name = this.state.eventInfo['name'];
            if (this.state.eventInfo['logo']) eventLogo = `data:image/png;base64,${this.state.eventInfo['logo']}`;
        }

        return (
            <header style={{backgroundColor: `#${(color).substr(2)}`}}>
                {name != null && name.length > 0 ? <p>{name}</p>:null}
                <img src={eventLogo} alt="Logo" />
            </header>
        );
    };

    isPasswordCorrect = () => {
        this.getFromBackend(`validate-password/${this.props.eventCode}`, 'POST', JSON.stringify({password: this.state.eventPassword})).then((data) => {
            this.setState({
                isPasswordCorrect: data === "true",
            });
        }).catch((e) => {
            console.error(e);
            this.setState({
                isPasswordCorrect: false,
            });
        });
    };

    render() {
        let color = 'ff00cfb4';
        if (this.state.eventInfo !== null) {
            if (this.state.eventInfo['color']) color = this.state.eventInfo['color'];
        }

        if (!this.state.hasEventCode) {
            return (
                <div className="App">
                    {this.getHeader()}
                    <FillInEventCode />
                </div>
            );
        }

        if (!this.state.isPasswordCorrect) {
            return (
                <div className="App">
                    {this.getHeader()}
                    <FillInEventPassword
                        setPassword={(password) => {
                            this.setState({
                                eventPassword: password,
                            });
                            this.isPasswordCorrect();
                        }}
                        isPasswordCorrect={this.state.isPasswordCorrect}
                    />
                </div>
            );
        }

        if (this.state.eventInfo === null) {
            return (
                <div className="App">
                    {this.getHeader()}
                    <Loading hasError={this.state.errorWhileLoading} />;
                </div>
            );
        }

        return (
            <div className="App" style={{backgroundColor: `#${(color).substr(2)}`}}>
                {this.getHeader()}
                <div className="wrapper" style={{height: 'calc(100% - 150px - 20px)'}}>
                    <AdminPanel
                        getFromBackend={this.getFromBackend}
                        eventCode={this.props.eventCode}
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(App);
