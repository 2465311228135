import React from "react";
import PropTypes from "prop-types";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class AdminPanelButton extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
        };
    }

    onPressed = async () => {
        this.setState({isLoading: true});

        await this.props.onPressed();

        this.setState({isLoading: false});
    }
    render() {
        return (
            <div className="admin-panel-button-wrapper clearfix">
                <p>{this.props.description}</p>
                <button onClick={this.onPressed}>
                    {this.props.title}
                    {this.state.isLoading ? ' ...' : ''}
                </button>
            </div>
        );
    }
}
AdminPanelButton.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    onPressed: PropTypes.func,
};

const AdminPanel = ({ getFromBackend, eventCode }) => {
    return (
        <div className="admin-panel">
            <AdminPanelButton
                title="Download alle resultaten als CSV-bestand"
                description="Hiermee kunnen alle resultaten worden gedownload als CSV-bestand. Dit bestand kan worden ingeladen in Excel."
                onPressed={async () => {
                    await getFromBackend(`results/${eventCode}`).then((data) => {
                        data = JSON.parse(data);

                        // TODO: Maybe create a map with userCode as key to make every user unique.

                        // Create CSV file
                        let csv = 'Gebruikerscode,Voornaam,Tussenvoegsel,Achternaam,Geboortedatum,Geslacht,E-mailadres,Aantal SmartClips geraakt,Gemiddelde reactietijd\n';

                        for (let i = 0; i < data.length; i++) {
                            let {
                                userCode,
                                firstName,
                                middleName,
                                lastName,
                                birthDate,
                                gender,
                                email,
                                hitClips,
                                averageTime,
                            } = data[i];

                            if (!userCode) userCode = '';
                            if (!firstName) firstName = '';
                            if (!middleName) middleName = '';
                            if (!lastName) lastName = '';
                            if (!birthDate) birthDate = '';
                            if (!gender) gender = '';
                            if (!email) email = '';

                            const row = `${userCode},${firstName},${middleName},${lastName},${birthDate},${gender},${email},${hitClips},${averageTime}\n`;

                            csv += row;
                        }

                        const hiddenDownloadElement = document.createElement('a');
                        hiddenDownloadElement.href = `data:text/csv;charset=utf-8,${encodeURI(csv)}`;
                        hiddenDownloadElement.target = '_blank';

                        hiddenDownloadElement.download = `event_data_${new Date().toISOString()}.csv`;
                        hiddenDownloadElement.click();

                    }).catch((e) => console.error(e));
                }}
            />
            <AdminPanelButton
                title="Reset alle resultaten"
                description="Hiermee worden alle resultaten verwijderd. Let op: dit kan niet worden teruggedraaid! Download daarom eerst alle resultaten als CSV-bestand voordat alle gegevens verwijderd worden."
                onPressed={async () => {
                    if (window.confirm('Weet je zeker dat je alle resultaten wilt resetten?')) {
                        await getFromBackend(`results/${eventCode}`, 'DELETE').then((_) => {
                            alert('Alle resultaten zijn verwijderd!');
                        }).catch((e) => console.error(e));
                    }
                }}
            />
        </div>
    );
}
AdminPanel.propTypes = {
    getFromBackend: PropTypes.func,
    eventCode: PropTypes.string,
};

export default AdminPanel;